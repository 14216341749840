<template>
  <v-scale-transition>
    <kits-panel :kits-items="mnpKits">
      <h3 class="headline grey--text text-xs-center hidden-sm-and-down">
        Mnp
      </h3>
    </kits-panel>
  </v-scale-transition>
</template>

<script>
export default {
  components: {
    KitsPanel: () =>
      import(/* webpackChunkName: "kitsPanel" */ '@/components/KitsPanel'),
  },
  data () {
    return {}
  },
  computed: {
    mnpKits: function () {
      const menus = [
        {
          icon: 'mdi-clipboard-flow-outline',
          path: '/support/mnp_request_list',
          title: 'Mnp Status',
        },
        // {
        //   icon: 'mdi-sim-off-outline',
        //   path: '/support/reject_mnp',
        //   title: 'Reject Mnp',
        // },
        {
          icon: 'mdi-sim-outline',
          path: '/support/mnp_imsi',
          title: 'Imsi',
        },
        {
          icon: 'mdi-sim-off-outline',
          path: '/support/mnp_reject',
          title: 'Mnp Reject',
        },
      ]

      return menus
    },
  },
}
</script>
